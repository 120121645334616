<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <div class="deleteAccountTitleArea">
                <div>
                  <v-card-title class="headline">탈퇴하기</v-card-title>
                  <v-card-subtitle>계정정보 및 회원 탈퇴 안내</v-card-subtitle>
                </div>
                <div class="d-flex align-center pr-4">
                  <v-btn icon @click="$router.back()">
                    <v-icon size="30">mdi-arrow-left</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-card-text>
                <v-divider />
                <v-form ref="form" v-model="valid" class="pt-6" lazy-validation>
                  <v-text-field
                    label="이메일"
                    name="email"
                    type="email"
                    :value="$store.state.auth_uuid"
                    disabled
                  ></v-text-field>
                  <v-text-field
                    label="비밀번호"
                    name="password"
                    type="password"
                    :rules="passwordRules"
                    v-model="password"
                  ></v-text-field>
                  <v-text-field
                    label="비밀번호 확인"
                    name="passwordConfirm"
                    type="password"
                    v-model="passwordConfirm"
                    :rules="passwordConfirmRules"
                  ></v-text-field>
                  <v-alert type="info" dense text class="mt-2">
                    <h3>약관사항</h3>

                    <div class="deleteInfoTitle">
                      개인정보 및 서비스 이용기록 삭제
                    </div>
                    <div class="deleteInfoBody">
                      개인정보 및 개인화 서비스 이용기록이 모두 삭제되며,
                      <br />삭제된 데이터는 복구되지 않습니다.
                    </div>
                    <div class="deleteInfoTitle">개인정보 보관</div>
                    <div class="deleteInfoBody">
                      회원탈퇴시 일부 개인정보는 개인정보처리방침에 따라
                      탈퇴일로부터 <br />30일간 보관됩니다.
                    </div>
                    <div class="deleteInfoTitle">탈퇴 후 제한</div>
                    <div class="deleteInfoBody">
                      탈퇴 처리된 이메일 ID는 30일간 재가입이 불가능합니다.
                    </div>
                  </v-alert>

                  <v-checkbox
                    v-model="termsAccepted"
                    label="약관에 동의합니다."
                    required
                  ></v-checkbox>
                  <v-btn
                    block
                    color="error"
                    @click="deleteAccount"
                    :disabled="!isFormValid"
                    >탈퇴하기</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <SnackBar />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { checkUserPw, deleteAccount } from "@/api/user";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
export default {
  components: {
    SnackBar,
  },
  data() {
    return {
      valid: true,
      email: "",
      password: "",
      passwordConfirm: "",

      passwordRules: [(v) => !!v || "비밀번호는 필수 항목입니다."],
      passwordConfirmRules: [
        (v) => !!v || "비밀번호 확인은 필수 항목입니다.",
        (v) => v === this.password || "비밀번호가 일치하지 않습니다.",
      ],
      termsAccepted: false,
    };
  },
  computed: {
    isFormValid() {
      return (
        this.password.length &&
        this.passwordConfirm.length &&
        this.password === this.passwordConfirm &&
        this.termsAccepted
      );
    },
  },
  methods: {
    async deleteAccount() {
      const result = await checkUserPw({
        uuid: this.$store.state.auth_uuid,
        password: this.password,
      });
      if (!result.data)
        return this.$store.commit(
          "setSnackBar",
          `현재 비밀번호와 일치하지않습니다.`,
        );
      if (this.$refs.form.validate()) {
        if (confirm("정말로 탈퇴하시겠습니까?")) {
          const result = await deleteAccount({
            id: this.$store.state.auth_id,
          });
          if (result.data === "isAvailableUser") {
            return this.$store.commit(
              "setSnackBar",
              `관리자는 모든 유저가 탈퇴 시 탈퇴가 가능합니다.`,
            );
          }
          if (result.data === true) {
            this.$store.commit("setSnackBar", `회원 탈퇴가 완료되었습니다.`);
            this.$store.dispatch("LOGOUT");
            this.$router.push({
              name: "login",
            });
          }
          if (result.data === false) {
            this.$store.commit("setSnackBar", `회원 탈퇴에 실패하였습니다.`);
          }
        }
        // 여기에 회원 탈퇴 로직을 추가하세요.
      }
    },
  },
};
</script>

<style lang="scss" src="../common/scss/setting.scss"></style>
